import React, { FC } from 'react';
import {
  Divider as MuiDivider,
  DividerProps as MuiDividerProps,
} from '@mui/material';

interface DividerProps extends MuiDividerProps {}

const Divider: FC<DividerProps> = ({ ...rest }) => (
  <MuiDivider flexItem {...rest} />
);

export default Divider;
