import React, { FC, HtmlHTMLAttributes } from 'react';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import NavigateNext from '@mui/icons-material/NavigateNext';
import {
  IconButton,
  Paper,
  PaginationProps as MuiPaginationProps,
  SxProps,
} from '@mui/material';
import Typography from 'components/Typography/Typography';
import { Theme } from '@mui/system';
import { combineSX } from '../..';

export interface PaginationProps extends MuiPaginationProps {
  page: NonNullable<MuiPaginationProps['page']>;
  count: NonNullable<MuiPaginationProps['count']>;
  onChange: NonNullable<MuiPaginationProps['onChange']>;
}

const styles: { [key: string]: SxProps<Theme> } = {
  arrowButton: {
    '&.MuiIconButton-root': {
      padding: 0,
    },
  },
};

const Pagination: FC<PaginationProps> = ({ page, count, onChange, sx }) => {
  return (
    <Paper
      elevation={0}
      sx={combineSX(
        {
          display: 'inline-flex',
          alignItems: 'center',
          padding: 1,
          height: 40,
          borderRadius: '4px',
          border: '1px solid #C8C8D4',
          boxSizing: 'border-box',
        },
        sx,
      )}
    >
      <IconButton
        disabled={page <= 1}
        onClick={e => onChange(e, 1)}
        size="large"
        sx={styles.arrowButton}
      >
        <FirstPage />
      </IconButton>
      <IconButton
        disabled={page <= 1}
        onClick={e => onChange(e, page - 1)}
        size="large"
        sx={styles.arrowButton}
      >
        <NavigateBefore />
      </IconButton>
      <Typography style={{ margin: '0px 6px' }}>
        {page} / {count}
      </Typography>
      <IconButton
        disabled={page >= count}
        onClick={e => onChange(e, page + 1)}
        size="large"
        sx={styles.arrowButton}
      >
        <NavigateNext />
      </IconButton>
      <IconButton
        disabled={page >= count}
        onClick={e => onChange(e, count)}
        size="large"
        sx={styles.arrowButton}
      >
        <LastPage />
      </IconButton>
    </Paper>
  );
};

export default Pagination;
