import React, { useCallback, useRef, useState } from 'react';
import {
  Theme,
  TableCell,
  TableCellProps,
  Box,
  SortDirection,
} from '@mui/material';
import { combineSX } from '../../..';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Typography from 'components/Typography';

interface TableHeadCellProps<T> extends Omit<TableCellProps, 'onResize'> {
  identifier?: string;
  sortable?: boolean;
  sortDirection?: SortDirection;
  resizable?: boolean;
  width?: number;
  onResize?: (newWidth: number) => void;
  onSort?: (e: React.MouseEvent) => void;
  actionsColumn?: boolean;
}

export default function TableHeadCell<T>({
  identifier,
  resizable,
  width,
  children,
  sx,
  onResize,
  sortable = true,
  sortDirection,
  onSort,
  actionsColumn,
  ...rest
}: TableHeadCellProps<T>) {
  const ref = useRef<HTMLElement>();
  const [hovered, setHovered] = useState(false);
  const [resizing, setResizing] = useState(false);

  const onStartResize = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      setResizing(true);
      const x = e.clientX;
      const styles = window.getComputedStyle(ref.current!);
      const w = parseInt(styles.width, 10);
      let newWidth = w;

      const mouseMoveHandler = function (e: MouseEvent) {
        const dx = e.clientX - x;
        if (w + dx < 80) {
          return;
        }
        newWidth = w + dx;
        const newWidthInPx = `${newWidth}px`;
        ref.current!.style.minWidth = newWidthInPx;
        ref.current!.style.maxWidth = newWidthInPx;
        [].forEach.call(
          document.getElementsByClassName(`table-body-cell-${identifier}`),
          (col: HTMLElement) => {
            col.style.minWidth = newWidthInPx;
            col.style.maxWidth = newWidthInPx;
          },
        );
      };

      const mouseUpHandler = function () {
        setResizing(false);
        onResize?.(newWidth);
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
      };

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    },
    [identifier, onResize],
  );

  return (
    <TableCell
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      ref={ref}
      sx={combineSX(
        theme => ({
          boxSizing: 'border-box',
          height: 55,
          padding: 0,
          '&:first-of-type': {
            paddingLeft: 3,
          },
          '&:last-child': {
            paddingRight: actionsColumn ? 1.5 : 3,
          },
          overflow: 'hidden',
          borderBottom: `1px solid ${theme.palette.divider}`,
          ...(width && {
            minWidth: width,
            maxWidth: width,
          }),
          ...(actionsColumn && {
            paddingRight: 1.5,
            position: 'sticky',
            right: 0,
            background: 'white',
            borderLeft: `1px solid ${theme.palette.divider}`,
          }),
        }),
        sx,
      )}
      {...rest}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box
          className="LuiTableHeadCell-titleContainer"
          {...(sortable && { onClick: onSort })}
          sx={{
            flexGrow: 1,
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            overflow: 'hidden',
            padding: 1.5,
            color: 'text.secondary',
            ...(sortable && {
              cursor: 'pointer',
            }),
          }}
        >
          {typeof children === 'object' ? (
            <>{children}</>
          ) : (
            <Typography
              variant="subtitle1"
              color="textSecondary"
              // //@ts-ignore
              // component="span"
              noWrap
            >
              {children}
            </Typography>
          )}
          {sortable &&
            (() => {
              const Icon =
                sortDirection === 'desc' ? ArrowDownwardIcon : ArrowUpwardIcon;
              return (
                <Icon
                  sx={{
                    width: '18px',
                    height: '18px',
                    color: 'primary.main',
                    cursor: 'pointer',
                    visibility: hovered || sortDirection ? 'visible' : 'hidden',
                    ...(hovered &&
                      !sortDirection && {
                        opacity: 0.5,
                      }),
                  }}
                />
              );
            })()}
        </Box>
        {resizable && !actionsColumn && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              onMouseDown={onStartResize}
              sx={{
                paddingLeft: 1,
                paddingRight: 1,
                cursor: 'col-resize',
                userSelect: 'none',
                height: '100%',
                visibility: hovered || resizing ? 'visible' : 'hidden',
                marginLeft: 'auto',
              }}
            >
              <Box
                sx={{
                  width: 8,
                  height: '100%',
                  backgroundColor: 'primary.main',
                  opacity: resizing ? 1 : 0.5,
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </TableCell>
  );
}
