import React, { FC } from 'react';
import { Box, BoxProps } from '@mui/material';
import { combineSX } from 'helpers';
import Title from 'components/Title';

interface PanelProps extends Omit<BoxProps, 'title'> {
  title?: React.ReactNode;
  controls?: React.ReactNode;
  borders?: ('header' | 'content' | 'footer')[];
  footerControls?: React.ReactNode;
}

const Panel: FC<PanelProps> = ({
  title,
  controls,
  borders = ['content'],
  footerControls,
  children,
  sx,
  ...rest
}) => {
  return (
    <Box
      className="LuiPanel-root"
      sx={combineSX(
        {
          display: 'flex',
          flexDirection: 'column',
        },
        sx,
      )}
      {...rest}
    >
      {(title || controls) && (
        <Box
          className="LuiPanel-header"
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            pb: 2,
            ...(borders?.includes('header') && {
              p: 2,
              border: 1,
              borderRadius: 1,
              ...(borders.includes('content') && {
                borderBottom: 'unset',
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
              }),
              borderColor: 'divider',
            }),
          }}
        >
          {title && (
            <Box className="LuiPanel-headerTitle">
              {typeof title === 'string' ? <Title>{title}</Title> : title}
            </Box>
          )}
          {controls && (
            <Box className="LuiPanel-headerControls" sx={{ ml: 'auto' }}>
              {controls}
            </Box>
          )}
        </Box>
      )}
      <Box
        className="LuiPanel-content"
        sx={{
          py: 2,
          ...(borders?.includes('content') && {
            p: 2,
            border: 1,
            borderRadius: 1,
            ...(borders.includes('header') && {
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
            }),
            ...(borders.includes('footer') && {
              borderBottom: 'unset',
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            }),
            borderColor: 'divider',
          }),
        }}
      >
        {children}
      </Box>
      {footerControls && (
        <Box
          className="LuiPanel-footer"
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            pt: 2,
            ...(borders?.includes('footer') && {
              p: 2,
              border: 1,
              borderRadius: 1,
              ...(borders.includes('content') && {
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
              }),
              borderColor: 'divider',
            }),
          }}
        >
          {footerControls && (
            <Box className="LuiPanel-footerControls" sx={{ ml: 'auto' }}>
              {footerControls}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Panel;
