import React, { ReactNode, useMemo, useRef } from 'react';
import {
  Theme,
  Table as MuiTable,
  SxProps,
  Box,
  BoxProps,
} from '@mui/material';
import TableBody from './TableBody';
import TableHead from './TableHead';
import ColumnsCustomizationMenu from './ColumnsCustomizationMenu';
import TablePagination from 'components/TablePagination';
import Panel from 'components/Panel';
import { SubTableRows } from './SubTable';
import RefreshIcon from '@mui/icons-material/Refresh';
import TableLoader from './TableLoader';
import { TABLE_PAGINATION_DEFAULT_ITEMS_PER_PAGE } from 'components/TablePagination/TablePagination';
import IconButton from 'components/IconButton';
import useTranslation from 'hooks/useTranslation';

export interface Row<T> {
  data: T;
  activePos?: number;
  selected?: boolean;
  multiSelected?: boolean;
  expandable?: boolean;
  multiSelectable?: boolean;
  disabled?: boolean;
  subTableRows?: SubTableRows<any>;
}
export interface Rows<T> {
  [key: string]: Row<T>;
}

export type SortDirection = 'asc' | 'desc';
export interface Column<T> {
  header?: ReactNode;
  sortable?: boolean;
  headerClassName?: string;
  cell?: (row: Row<T>) => ReactNode;
  cellClassName?: string;
  visible?: boolean;
  sort?: { dir: SortDirection; pos: number };
  pos?: number;
  customizable?: boolean;
  resizable?: boolean;
  headerSX?: SxProps<Theme>;
  columnSX?: SxProps<Theme>;
  width?: number;
  actionsColumn?: boolean;
}

export interface Columns<T> {
  [key: string]: Column<T>;
}

export interface TableProps<T>
  extends Omit<BoxProps, 'onSelect' | 'title' | 'onResize'> {
  columns: Columns<T>;
  rows: Rows<T>;
  dataIdentifier: keyof T | (keyof T)[];
  loading?: boolean;
  onSelect?: (
    newRows: Rows<T>,
    doubleClick: boolean,
    //middleClick?: boolean,
  ) => void;
  onMultiSelect?: (newRows: Rows<T>, toggledRow?: Row<T>) => void;
  onSort?: (newColumns: Columns<T>) => void;
  renderExpandedRow?: (row: Row<T>) => ReactNode;
  totalItems?: number;
  isLastPage?: boolean;
  page: number;
  itemsPerPage: number;
  onChangePage: (newPage: number) => void;
  onChangeItemsPerPage: (newItemsPerPage: number) => void;
  title?: ReactNode;
  controls?: ReactNode;
  onColumnsSettingsChange?: (newColumns: Columns<T>) => void;
  filterMenu?: ReactNode;
  onResize?: (newColumns: Columns<T>, resizedColumnIdentifier: string) => void;
  onRefresh?: () => void;
}

export default function Table<T>({
  columns,
  rows,
  dataIdentifier,
  loading,
  onSelect,
  onMultiSelect,
  onSort,
  renderExpandedRow,
  totalItems,
  isLastPage,
  page,
  itemsPerPage,
  onChangePage,
  onChangeItemsPerPage,
  title,
  onColumnsSettingsChange,
  filterMenu,
  controls,
  onResize,
  onRefresh,
  ...rest
}: TableProps<T>) {
  const tableRef = useRef<any>(null);
  const { t } = useTranslation();

  const [tableInnerLoader, setTableInnerLoader] = React.useState(loading);
  React.useEffect(() => {
    setTableInnerLoader(loading);
  }, [loading]);

  const updatedColumns = useMemo(
    () =>
      Object.entries(columns).reduce((acc, [id, column], i) => {
        if (column.actionsColumn) {
          column.customizable = false;
          column.resizable = false;
          column.sortable = false;
        }
        if (!column.pos) {
          column.pos = i + 1;
        }
        acc[id] = column;
        return acc;
      }, {} as Columns<T>),
    [columns],
  );

  const isExpandable = useMemo(
    () => Boolean(renderExpandedRow),
    [renderExpandedRow],
  );

  return (
    <Box {...rest}>
      <Panel
        title={title}
        controls={
          (filterMenu || onColumnsSettingsChange || controls) && (
            <>
              {filterMenu}
              {onColumnsSettingsChange && (
                <ColumnsCustomizationMenu
                  columns={updatedColumns}
                  onChange={onColumnsSettingsChange}
                />
              )}
              {controls}
            </>
          )
        }
        sx={theme => ({
          '& .LuiPanel-content': {
            padding: 'unset',
            overflow: 'auto',
            position: 'relative',
          },
          '& .LuiPanel-headerControls': {
            display: 'flex',
            gap: 1,
          },
        })}
      >
        <MuiTable ref={tableRef} stickyHeader>
          <TableHead
            columns={updatedColumns}
            isExpandable={isExpandable}
            isMultiSelected={Object.values(rows).some(
              row =>
                row.multiSelected ||
                (row.subTableRows &&
                  Object.values(row.subTableRows).some(
                    subTableRow => subTableRow.multiSelected,
                  )),
            )}
            onMultiSelect={
              onMultiSelect &&
              (() => {
                const isAllActiveRowsSelected = Object.entries(rows)
                  .filter(([id, row]) => row.activePos !== undefined)
                  .every(([id, row]) => row.multiSelected);
                onMultiSelect(
                  Object.entries(rows).reduce(
                    (newRows, [identifier, row]: [keyof Rows<T>, Row<T>]) => {
                      newRows[identifier] = {
                        ...row,
                        ...(row.activePos !== undefined && {
                          multiSelected: !isAllActiveRowsSelected,
                          ...(row.subTableRows && {
                            subTableRows: Object.entries(
                              row.subTableRows,
                            ).reduce((acc, [subTableRowId, subTableRow]) => {
                              acc[subTableRowId] = {
                                ...subTableRow,
                                multiSelected: !isAllActiveRowsSelected,
                              };
                              return acc;
                            }, {} as SubTableRows<any>),
                          }),
                        }),
                      } as Row<T>;
                      return newRows;
                    },
                    {} as Rows<T>,
                  ),
                );
              })
            }
            onSort={onSort}
            onResize={onResize}
          />
          <TableBody
            columns={updatedColumns}
            rows={rows}
            dataIdentifier={dataIdentifier}
            onSelect={onSelect}
            onMultiSelect={onMultiSelect}
            renderExpandedRow={renderExpandedRow}
            noData={totalItems === 0 && !tableInnerLoader}
          />
        </MuiTable>
        {tableInnerLoader && <TableLoader tableRef={tableRef} />}
      </Panel>
      {(() => {
        const showRefreshButton = !!onRefresh;
        const showPagination =
          totalItems === undefined ||
          totalItems > TABLE_PAGINATION_DEFAULT_ITEMS_PER_PAGE;

        return (
          (showRefreshButton || showPagination) && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '12px',
                alignItems: 'center',
              }}
            >
              {showRefreshButton && (
                <IconButton
                  onClick={onRefresh}
                  tooltip={t('Refresh')}
                  sx={{ mr: 0.5 }}
                >
                  <RefreshIcon />
                </IconButton>
              )}
              {showPagination && (
                <TablePagination
                  page={page}
                  itemsPerPage={itemsPerPage}
                  totalItems={totalItems}
                  isLastPage={isLastPage}
                  onChangePage={onChangePage}
                  onChangeItemsPerPage={onChangeItemsPerPage}
                />
              )}
            </Box>
          )
        );
      })()}
    </Box>
  );
}
